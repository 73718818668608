$(() => {
  const $collapse = $('nav.navbar .navbar-collapse');

  if ($collapse.hasClass('show')) {
    $collapse.closest('nav').addClass('show');
  }

  $collapse
    .on('shown.bs.collapse', (event) => {
      if (!$collapse.is(event.target)) {
        return;
      }

      $collapse.closest('nav').addClass('show');
    })
    .on('hidden.bs.collapse', (event) => {
      if (!$collapse.is(event.target)) {
        return;
      }

      $collapse.closest('nav').removeClass('show');
    });
});
