
$(() => {
  $('.carousel').on('slide.bs.carousel', (event) => {
    const $carousel = $(event.currentTarget);
    const $carouselInnerCaptions = $carousel.find('.carousel-inner-captions');

    const $fromCaption = $carouselInnerCaptions.find(`[data-slide-to=${event.from}]`);
    const $toCaption = $carouselInnerCaptions.find(`[data-slide-to=${event.to}]`);

    if ($fromCaption && $toCaption) {
      $fromCaption.removeClass('active');
      $toCaption.addClass('active');
    }
  });
});
