$(() => {
  $('.use-redirect-modal').on('click', (event) => {
    event.preventDefault();
    const $link = $(event.currentTarget);
    const link = $link.attr('href');
    const target = $link.attr('target');

    const modalText = $link.data('modal-text');
    const modalCancelText = $link.data('modal-cancel-text');
    const modalContinueText = $link.data('modal-continue-text');

    const $modal = $('.modal-redirect');

    const $modalBody = $modal.find('.modal-body p');
    const $modalCancelAction = $modal.find('.modal-footer .btn-link');
    const $modalContinueAction = $modal.find('.modal-footer .btn-primary');

    // reset modal buttons
    $modalCancelAction.text($modalCancelAction.data('default-value'));
    $modalContinueAction.text($modalContinueAction.data('default-value'));

    // setup modal
    $modalBody.text(modalText);
    $modalContinueAction.attr('href', link);
    $modalContinueAction.attr('target', target);

    if (modalCancelText) {
      $modalCancelAction.text(modalCancelText);
    }

    if (modalContinueText) {
      $modalContinueAction.text(modalContinueText);
    }

    $modal.modal('show');

  });
});
