$(() => {
  class DGDSelect {
    static knownSelects = [];

    /**
     * Closes all known selectors (except a single one, optional).
     */
    static closeAll(exception) {
      DGDSelect.knownSelects.forEach((select) => {
        if (select !== exception) {
          select.close();
        }
      });
    }

    constructor($originalSelect) {
      [this.originalSelect] = $originalSelect;
      this.$submitBtn = $originalSelect.parents('form').find('[type="submit"]');

      this.$select = $('<div class="dgd-select">');
      this.$placeholder = $('<div class="dgd-select-placeholder">');
      this.$button = $('<div class="dgd-select-button">');
      this.$optionsContainer = $('<div class="dgd-select-options">');
      this.isOpen = false;
      this.optionsChanged = false;
      this.autosubmit = $originalSelect.hasClass('select-autosubmit');

      // Build elements for every option
      // HTMLSelectElement.options is a HTMLOptionsCollection, not an array
      for (let i = 0; i < this.originalSelect.options.length; i += 1) {
        const option = this.originalSelect.options[i];
        if (option.text === '') {
          // eslint-disable-next-line no-continue
          continue;
        }

        const $option = $('<div class="dgd-select-option">')
          .text(option.text)
          .attr('data-value', option.value)
          .on('click', event => this.handleOptionClick(option.value, event));

        if (this.originalSelect.multiple) {
          $option.prepend($('<div class="dgd-checkbox">✓</div>'));
        }

        $option.appendTo(this.$optionsContainer);
      }

      // Listen events
      DGDSelect.knownSelects.push(this);
      this.$select.on('click', event => this.handleSelectClick(event));
      this.$optionsContainer.on('click', () => false); // Do not close if you miss an option for a few pixels

      // Add classes for multiple or single option modes
      if (this.originalSelect.multiple) {
        this.$select.addClass('multiple');
        this.$optionsContainer.addClass('multiple');
      } else {
        this.$select.addClass('single');
        this.$optionsContainer.addClass('single');
      }

      this.$placeholder.appendTo(this.$select);
      this.$button.appendTo(this.$select);
      this.close();
      this.updateCurrentState();

      // Reflows
      this.$select.insertAfter($originalSelect);
      this.$optionsContainer.appendTo(document.body);
      $(this.originalSelect).hide();
    }

    open() {
      this.isOpen = true;
      this.$optionsContainer.show();
      this.updateOptionsPlacement();
    }

    close() {
      this.$optionsContainer.hide();

      // Prevent autosubmit if already closed or nothing changed
      if (this.autosubmit && this.isOpen && this.optionsChanged) {
        this.originalSelect.form.submit();
      }

      this.isOpen = false;
    }

    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }

    /**
     * Updates the state of the javascript component based on the HTMLSelectElement's state.
     */
    updateCurrentState() {
      let selectedCount = 0;
      let selectedOptions = [];

      // Find selected option(s)
      for (let i = 0; i < this.originalSelect.options.length; i += 1) {
        const option = this.originalSelect.options[i];
        if (option.selected) {
          selectedCount += 1;
          selectedOptions.push(option);
        }
      }

      // Handle the empty option required to allow unselection
      if (selectedCount === 1 && selectedOptions[0].text === '') {
        selectedCount = 0;
        selectedOptions = [];
      }

      // Update placeholder text:
      if (selectedCount === 1) {
        this.$placeholder.text(selectedOptions[0].text);
      } else if (selectedCount > 1) {
        this.$placeholder.text(`${selectedCount} seleccionadas`);
      } else {
        this.$placeholder.text(this.originalSelect.dataset.placeholder);
      }

      // Update option selection status
      this.$optionsContainer.find('.dgd-select-option').removeClass('selected');
      selectedOptions.forEach((option) => {
        const $option = this.$optionsContainer.find(`.dgd-select-option[data-value="${option.value}"]`);
        $option.addClass('selected');
      });
    }

    /**
     * Adjust the size and position of the floating options menu to match
     * the current size and position of the this.$select container.
     */
    updateOptionsPlacement() {
      const { top, left } = this.$select.offset();
      const height = this.$select.outerHeight();
      const width = this.$select.outerWidth();
      this.$optionsContainer.css({
        position: 'absolute',
        top: `${top + height}px`,
        left: `${left}px`,
        width: `${width}px`
      });
    }

    handleSelectClick() {
      this.toggle();
      DGDSelect.closeAll(this);
      return false;
    }

    handleOptionClick(value) {
      const [option] = $(this.originalSelect).find(`option[value="${value}"]`);
      option.selected = !option.selected;
      this.optionsChanged = true;

      this.updateCurrentState();
      // For multi-selects:
      if (this.originalSelect.multiple) {
        // Stop propagation, keep this select open
        DGDSelect.closeAll(this);
        return false;
      }
      // For single selects:
      // Keep propagation if possible, but closing everything
      DGDSelect.closeAll();
      return true;
    }
  }

  // Auto-initialization
  $('select').not('no-js-select').each((i, item) => {
    // eslint-disable-next-line no-new
    new DGDSelect($(item));
  });
  $(document).on('click', () => {
    DGDSelect.closeAll();
  });
});
