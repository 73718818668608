$(() => {
  const url = encodeURIComponent(document.URL);
  const settings = 'menubar=no,toolbar=no,status=no,width=670,height=570';
  const title = $('meta[name="description"]').attr('content');
  const description = $('meta[property="og:description"]').attr('content');
  $('.share-link.twitter').on('click', (e) => {
    e.preventDefault();
    const link = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
    window.open(link, 'Sharing', settings);
  });

  $('.share-link.facebook').on('click', (e) => {
    e.preventDefault();
    const link = `https://www.facebook.com/sharer.php?u=${url}`;
    window.open(link, 'Sharing', settings);
  });

  $('.share-link.linkedin').on('click', (e) => {
    e.preventDefault();
    const link = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`;
    window.open(link, 'Sharing', settings);
  });
});
