$(() => {
  const $observer = $('#scrollObserver');
  const $cmsToolbar = $('.cms-toolbar');
  const $body = $('body');

  if ($cmsToolbar.length) {
    const toolbarPlusNavDataOffset = 135;
    $body.data('offset', toolbarPlusNavDataOffset);
  }

  if ($observer.length) {
    const navScrollspyOffset = $body.data('offset') + 145;
    const toolbarAndNavScrollspyOffset = $body.data('offset') + 95;
    const scrollspyOffset = $cmsToolbar.length ? toolbarAndNavScrollspyOffset : navScrollspyOffset;
    $body.scrollspy({ target: '#scrollObserver', offset: scrollspyOffset });
  }
});
